import styled from "@emotion/styled";

/**
 *
 * @param {string} 		label
 * @param {string} 		formKey
 * @param {function} 	handleFormValueChange
 *
 * @return {JSX.Element}
 */

export default function FormField({
  label,
  formKey,
  required,
  handleFormValueChange,
}) {
  return (
    <Wrapper>
      <label htmlFor={formKey}>{label}</label>
      <input
        id={formKey}
        name={formKey}
        onChange={(e) => handleFormValueChange(formKey, e.target.value)}
        required={required}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-bottom: 12px;

  label {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 2px;
    text-transform: uppercase;
  }

  input {
    border: none;
    border-bottom: 1px solid white;
    background: none;
    height: 24px;
    box-sizing: border-box;
    width: 100%;
    color: white;
  }
`;
