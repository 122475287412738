// Fonctions permettant de simplifier l'utilisation localStorage
async function getStoreItem(key) {
  return await JSON.parse(localStorage.getItem(key));
}

function setStoreItem(key, data) {
  localStorage.setItem(key, JSON.stringify(data))
}

export {
  getStoreItem,
  setStoreItem,
}