import styled from "@emotion/styled";
import { BlueBox, Button, GoldBox, ScreenWrapper } from "../components";

/**
 *
 * @param setScreen
 * @param {object} parmas
 * @param {object} params.prizeResult
 * @constructor
 */
export default function ResultScreen({setScreen, params}) {

  const prize = params.prizeResult;

  const prizeMessage = prize.win ?
    prize.data.jackpot ?
      <p>Vous avez gagné un diner pour 2 au restaurant étoilé<br/><span>Le Jules Verne</span></p>
      :
      <p>Vous avez gagné<br/>Le Gel Douche BOSS</p>
    :
    <p>Vous avez perdu mais merci d'avoir participé à ce concours</p>


  if (!prize) {
    return;
  }

  return (
    <ScreenWrapper>
      {!prize.win &&
        <BlueBox>
          <div className={"bb-message"}>
            <h2 className={"bb-title"}>Dommage&nbsp;!</h2>
            {prizeMessage}
          </div>
        </BlueBox>
      }
      {prize.win &&
        <>
          {prize.data && prize.data.jackpot &&
            <Particles/>
          }
          <GoldBox>
            <h2>Bravo !</h2>
            <div className={"gb-message"}>
              {prizeMessage}
            </div>
            <Button
              text={"Suivant"}
              handleClick={() => setScreen({name: "Email"})}
              dark={true}
            />
          </GoldBox>
        </>
      }
      {prize.data && !prize.data.jackpot &&
        <Gel>
          <img src={"gel.png"} alt={"gel douche BOSS"}/>
        </Gel>
      }
    </ScreenWrapper>
  )
}

const Gel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -32px;
  width: 90%;
  max-width: 400px;
  aspect-ratio: 1/1;
  background-image: url("/particles.webp");
  background-size: contain;
  background-repeat: no-repeat;

  img {
    width: 80%;
  }
`;

const Particles = styled.div`
  position: absolute;
  top: 460px;
  width: 150%;
  max-width: 600px;
  aspect-ratio: 1/1;
  margin-top: -400px;
  background-image: url("/particles.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
`;
