import styled from '@emotion/styled'
import Header from "./Header";

export default function ScreenWrapper({children}) {

  return(
    <Wrapper>
      <Header/>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
  font-family: Averta, sans-serif;
`;