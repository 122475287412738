import styled from "@emotion/styled";


export default function GoldBox({children}) {
  return(
    <Wrapper>
      <div className={"inner-box"}>
        {children}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  margin-top: 24px;
  width: 100%;
  max-width: 360px;
  padding: 5px;
  background: linear-gradient(90deg, #8E917B 0%, #FFFFFF 50%, #8E917B 100%);
  color: var(--blue);

  .inner-box {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 24px;
    box-shadow: inset 2px 2px 6px 3px rgba(0, 0, 0, 0.4)
  }
  
  h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 3rem;
  }

  .gb-message {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
    p {
      margin-top: 16px;
    }
    span {
      font-weight: 700;
      font-size: 2rem;
    }
  }
  
  .gb-small {
    text-align: left;
    line-height: 16px;
    width: 100%;
    margin-bottom: 8px;
  }
  

  .gb-link {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-decoration: none;
    height: 48px;
    width: 100%;
    border: none;
    font-size: 17px;
    font-weight: 800;
    cursor: pointer;
    color: var(--blue);
    background: var(--gradient);
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.3);
  }

  .errors {
    ul {
      margin-top: 0;
      padding-top: 0;
      padding-left: 24px;
    }
    text-align: start;
    color: #ec3b24;
  }
`;