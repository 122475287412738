import { ScreenWrapper, Button } from "../components";
import { useFormData } from "../hooks/useFormData";
import { updateWinnerInformations } from "../requests";
import { useState } from "react";
import FormField from "../components/FormField";
import BlueBox from "../components/BlueBox";
import styled from "@emotion/styled";

const fields = [
  {
    formKey: "firstName",
    label: "Nom *",
    required: true,
  },
  {
    formKey: "lastName",
    label: "Prénom *",
    required: true,
  },
  {
    formKey: "address",
    label: "Adresse *",
    required: true,
  },
  {
    formKey: "address_add",
    label: "Complément d'adresse",
  },
  {
    formKey: "postal_code",
    label: "Code postal *",
    required: true,
  },
  {
    formKey: "city",
    label: "Ville *",
    required: true,
  },
  {
    formKey: "phone",
    label: "Téléphone *",
    required: true,
  },
];

export default function FormScreen({ setScreen, session }) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formValues, handleFormValueChange, setFormValues] = useFormData({
    firstName: "",
    lastName: "",
    address: "",
    address_add: "",
    postal_code: "",
    city: "",
  });

  const [infoSent, setInfoSent] = useState(false);

  function isFormValid() {
    let res = true;
    fields.forEach((field) => {
      if (formValues[field.formKey]?.length < 1) {
        if (field.formKey !== "address_add") {
          res = false;
        }
      }
    });
    return res;
  }

  const handleFormSubmit = () => {
    setIsSubmitted(true);

    // On vérifie que toutes les informations soient présentes
    // avant de soumettre le formulaire
    if (!isFormValid()) {
      return;
    }

    updateWinnerInformations(
      session.user.id,
      formValues.firstName,
      formValues.lastName,
      formValues.address,
      formValues.address_add,
      formValues.postal_code,
      formValues.city
    ).then((response) => {
      if (response.length > 0) {
        setInfoSent(true);
      }
      localStorage.setItem("end_form_submitted", "yes");
    });
  };

  if (infoSent || localStorage.getItem("end_form_submitted") === "yes") {
    return (
      <ScreenWrapper>
        <BlueBox>
          <div style={{ height: 16 }} />
          <Text className={"gb-message"}>
            <strong>Merci !</strong>
            Vous allez bientôt
            <br />
            recevoir votre
            <br />
            cadeau
          </Text>
          <div style={{ height: 64 }} />
        </BlueBox>
        <ImageWrapper>
          <img
            className={"bottle-img"}
            src={"bottle.png"}
            alt={"bouteille de parfum boss"}
          />
        </ImageWrapper>
      </ScreenWrapper>
    );
  }

  return (
    <ScreenWrapper>
      <BlueBox>
        <h2>
          formulaire
          <br />
          pour envoi postal
        </h2>
        <div style={{ height: 16 }} />

        {fields &&
          fields.map((field, key) => (
            <>
              <FormField
                key={key}
                formKey={field.formKey}
                label={field.label}
                required={field.required}
                handleFormValueChange={handleFormValueChange}
              />
              <div style={{ height: 8 }} />
            </>
          ))}
        <div style={{ height: 16 }} />

        {!!isSubmitted && (
          <div className={"errors"}>
            <ul>
              {fields.map((field, key) => {
                if (formValues[field.formKey]?.length < 1) {
                  if (field.formKey !== "address_add") {
                    return <li key={key}>{field.label} obligatoire</li>;
                  }
                } else {
                  return null;
                }
              })}
            </ul>
          </div>
        )}

        <div style={{ height: 8 }} />
        <small>
          * Ces informations seront utilisées uniquement pour vous envoyer votre
          cadeau
        </small>

        <ButtonWrapper>
          <Button text={"Suivant"} handleClick={() => handleFormSubmit()} />
        </ButtonWrapper>
      </BlueBox>

      <div style={{ height: 64 }} />
    </ScreenWrapper>
  );
}

const ButtonWrapper = styled.div`
  margin: 0 auto -16px;
  max-width: 230px;
  transform: translateY(50%);
`;

const ImageWrapper = styled.div`
  margin: -40px auto 0;
  position: relative;
  z-index: 2;

  max-width: min(16vh, 40vw);

  img {
    aspect-ratio: 106/244;
    width: 100%;
  }
`;


const Text = styled.p`
  margin: 0;
  padding: 0 0.5em;
  font-size: min(2.5vh, clamp(12px, 10vw, 20px));


  strong {
    display: block;
    font-size: 3em;
  }

  // media query aspect ratio iphone SE
  @media screen and (max-width: 425px) and (min-aspect-ratio: 370/670) {
    font-size: 4.2vw;
  }
`;
