import { supabase } from "./supabaseClient";
import { v4 as uuidv4 } from 'uuid';

export const createUser = async (email, attachment) => {
  return await supabase.auth.signUp({
    email: email,
    password: email,
    options: {
      data: {
        attachment,
      }
    }
  })
}

export const updateUser = async (payload) => {
  return await supabase.auth.updateUser({
    data: {
      ...payload
    }
  });
}

export const getUserProfile = async (userId) => {
  return await supabase
  .from('profiles')
  .select()
}


export async function uploadFile(file) {
  const uuid = uuidv4()
  const filePath = `${uuid}_${file.name}`
  const { data, error } = await supabase
    .storage
    .from('bossbottled-nocibe-attachments')
    .upload(filePath, file, {
      cacheControl: '3600',
      upsert: false,
    })

    console.log('data', data);

    return data;
}


export const updateWinnerInformations = async (
  id,
  firstname,
  lastname,
  address,
  address_add,
  postal_code,
  city
) => {
  console.log('id', id);
  const { data, error } = await supabase
    .from('profiles')
    .update(
      {
        firstname: firstname,
        lastname: lastname,
        address: address,
        address_add: address_add,
        postal_code: postal_code,
        city: city
      })
    .eq('id', id)
    .select()

    console.log('data', data);
    console.log('error', error);
  if (error) {
    throw error;
  }
  return data;
}

export const getDrawDone = async () => {
  const { data, error } = await supabase
    .from('draws')
    .select()
  if (error) {
    throw error;
  }

  console.log('data', data);

  if (data.length === 0) {
    return false;
  }

  return true;
}