import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/fonts/Averta-Bold.otf';
import './assets/fonts/Averta-Extrabold.otf';
import './index.css';


import App from './App.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
