import styled from "@emotion/styled";
import { useState } from "react";

export default function FileInput({
  label,
  formKey,
  handleFormValueChange,
  required,
}) {
  const [value, setValue] = useState(null);

  const onChange = (e) => {
    handleFormValueChange(formKey, e.target.files[0]);
    setValue(e.target.files[0].name);
  };

  return (
    <Wrapper className={value ? "has-value" : ""}>
      <label htmlFor={formKey}>{value ? value : label}</label>
      <div className="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.35 20.05">
          <path
            d="M355.73,462.06h-8a1.09,1.09,0,0,1-.36-.06.54.54,0,0,1-.32-.52,2.17,2.17,0,0,1,0-.26c0-2.93,0-5.87,0-8.8a.56.56,0,0,1,.45-.62.57.57,0,0,1,.7.51v8.42c0,.17,0,.17.18.17H363.1c.11,0,.16,0,.15-.14a2.43,2.43,0,0,1,0-.27v-8.08a.58.58,0,1,1,1.16-.06v9a.59.59,0,0,1-.67.66Z"
            transform="translate(-346.55 -442.51)"
          />
          <path
            d="M355.15,444.93,355,445l-3,3.05a.61.61,0,0,1-.75.18.57.57,0,0,1-.17-.9c.13-.14.28-.26.42-.39l4.13-3.82c.2-.18.26-.17.45,0l4.18,4.06a1,1,0,0,1,.24.31.54.54,0,0,1-.2.69.57.57,0,0,1-.74-.07l-1.73-1.73-1.4-1.39a.37.37,0,0,0-.11-.09v12.34a.77.77,0,0,1-.11.49.57.57,0,0,1-1-.29,2.81,2.81,0,0,1,0-.29q0-6,0-12.11Z"
            transform="translate(-346.55 -442.51)"
          />
        </svg>
      </div>
      <input
        type="file"
        id={formKey}
        name={formKey}
        required={required}
        onChange={onChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border: 1px solid white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 12px 10px;

  .icon {
    line-height: 0;
  }

  svg {
    fill: white;
    width: 17px;
    height: 20px;
  }

  label {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0px;
    text-transform: uppercase;
  }

  input {
    border: none;
    border-bottom: 1px solid white;
    background: none;
    height: 36px;
    box-sizing: border-box;
    width: 100%;
    color: white;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;
