import styled from '@emotion/styled'

export default function Button({text, handleClick, dark, maxWidth}) {

  return(
    <StyledButton
      dark={dark}
      maxWidth={maxWidth}
      onClick={handleClick}>
      {text}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  position: relative;
  height: 48px;
  width: 100%;
  border: none;
  font-size: 15px;
  font-family: Averta, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 20px;

  max-width: ${props => props.maxWidth ? props.maxWidth : "100%"};
  color: ${props => props.dark ? "#fff" : "var(--blue)"};
  background: ${props => props.dark ? "var(--blue)" : "linear-gradient(90deg, #AAAA90 0%, #FAFBF9 50%, #9A9780 100%);"};
  box-shadow: 2px 2px 1px rgba(0,0,0,0.3);
  
  &:hover {
    opacity: 0.8;
  }  
`;



