import styled from "@emotion/styled";

export default function CheckBox({label, formKey, handleFormValueChange}) {


  return(
    <Wrapper>
      <label htmlFor={formKey}>{label}</label>
      <input
        onChange={(e) => handleFormValueChange(formKey, e.target.checked)}
        type={"checkbox"}
        id={formKey}
      />
    </Wrapper>
  )

}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  label {
    font-size: 12px;
    text-align: left;
    line-height: 18px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  input[type="checkbox"] {
    appearance: none;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 9999px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    box-sizing: content-box;
  }

  input[type="checkbox"]::after {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
    content: '\\2713';
    border-radius: 9999px;
    color: white;
    font-size: 12px;
    width: 18px;
    height: 18px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: #262a35;
  }

  input[type="checkbox"]:checked::after {
    transform: scale(1);
  }
`;