import { Button, ScreenWrapper } from "../components";
import BlueBox from "../components/BlueBox";
import styled from "@emotion/styled";

export default function CongratzScreen({ setScreen }) {
  return (
    <ScreenWrapper>
      <BlueBox>
        <div style={{ height: 12 }} />
        <Text className={"gb-message"}>
          <strong>Bravo !</strong>
          Vous avez gagné :
        </Text>

        <PrizeWrapper>
          <img src={"ball.png"} alt={"prize"} />
          <p>
            Un ballon signé par
            <br />
            Eduardo Camavinga
          </p>
        </PrizeWrapper>
        <div style={{ height: 0 }} />

        <ButtonWrapper>
          <Button
            text={"Je reçois mon cadeau"}
            handleClick={() => setScreen({ name: "FormScreen" })}
          />
        </ButtonWrapper>
      </BlueBox>
      <div style={{ height: 64 }} />
    </ScreenWrapper>
  );
}

const ButtonWrapper = styled.div`
  margin: 0 auto -16px;
  max-width: 230px;
  transform: translateY(50%);
`;

const PrizeWrapper = styled.div`
  position: relative;
  
  img {
    border: 2px solid #399fd8;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #399fd8);
    width: 64%;
    object-fit: contain;
    margin: 10px auto;
  }
`;

const Text = styled.p`
  margin: 0 0 16px;
  padding: 0 0.5em;
  font-size: min(2.5vh, clamp(12px, 10vw, 20px));

  strong {
    display: block;
    font-size: 3em;
  }

  // media query aspect ratio iphone SE
  @media screen and (max-width: 425px) and (min-aspect-ratio: 370/670) {
    font-size: 4.2vw;
  }
`;
