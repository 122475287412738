import { ScreenWrapper } from "../components";
import BlueBox from "../components/BlueBox";
import styled from "@emotion/styled";

export default function WaitingScreen({ session }) {
  return (
    <ScreenWrapper>
      <BlueBox>
        <div style={{ height: 16 }} />
        <Text className={"gb-message"}>
          VOTRE EMAIL A BIEN
          <br />
          ÉTÉ VALIDÉ.
          <br />
          <br />
          MERCI POUR VOTRE
          <br />
          PARTICIPATION !
          <br />
          <br />
          DÉCOUVREZ LES
          <br />
          RÉSULTATs DU CONCOURS
          <br />
          LE 17 Juin 2024
        </Text>
        <div style={{ height: 32 }} />
      </BlueBox>
      <ImageWrapper>
        <img
          className={"bottle-img"}
          src={"bottle.png"}
          alt={"bouteille de parfum boss"}
        />
      </ImageWrapper>
    </ScreenWrapper>
  );
}

const ImageWrapper = styled.div`
  margin: -32px auto 0;
  position: relative;
  z-index: 2;

  max-width: min(13vh, 32vw);

  img {
    aspect-ratio: 106/244;
    width: 100%;
  }
`;

const Text = styled.p`
  margin: 0;
  padding: 0 0.5em;
  font-size: min(2.5vh, clamp(12px, 10vw, 20px));

  // media query aspect ratio iphone SE
  @media screen and (max-width: 425px) and (min-aspect-ratio: 370/670) {
    font-size: 4.2vw;
  }
`;
