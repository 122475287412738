import { useEffect, useRef, useState } from "react";
import { supabase } from "./supabaseClient";
import { getStoreItem } from "./functions";
import "./App.css";

import {
  HomeScreen,
  EmailScreen,
  FormScreen,
  ResultScreen,
  WaitingScreen,
  ClosedScreen,
  CongratzScreen
} from "./screens";
import { getUserProfile, getDrawDone } from "./requests";

function App() {


  const [screen, setScreen] = useState(null);
  const [session, setSession] = useState(null);
  const [isWinner, setIsWinner] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [isDrawDone, setIsDrawDone] = useState(false);
  const subscriptionRef = useRef(null);

  const updateSession = session => {
    setSession(session)
    console.log('session', session);
    if (!session) return;

    setEmailConfirmed(session?.user?.email_confirmed_at);


    getUserProfile(session.user.id).then(({data, error}) => {
      if (!data || data.length === 0) return;

      const isWinner = data[0].is_winner;
      setIsWinner(isWinner);
    });
  }

  const renderScreen = (screen) => {
    switch (screen.name) {
      case "Home":
        return <HomeScreen setScreen={setScreen}/>;
      case "Result":
        return <ResultScreen setScreen={setScreen} params={screen.params}/>
      case "Email":
        return <EmailScreen setScreen={setScreen} params={screen.params}/>;
      default:
        return null;
    }
  }

  useEffect(() => {
    getDrawDone().then(done => {
      setIsDrawDone(done);

      supabase.auth.getSession().then(({data: {session}}) => {
        updateSession(session);
      })
  
      const {
        data: {subscription},
      } = supabase.auth.onAuthStateChange((_event, session) => {
        updateSession(session);
      })

      subscriptionRef.current = subscription;
  
      let initScreen = {name: "Home", param: null};
  
      getStoreItem("@state").then((data) => {
        console.log('data', data);
        if(data?.prizeResult) {
          initScreen = {
            name: data.screen,
            params: {
              waitingConfirmation: data.waitingConfirmation || false,
              prizeResult: data.prizeResult
            }};
        }
        setScreen(initScreen);
      });
    })

    return () => subscriptionRef.current?.unsubscribe()
  }, [])


  // L'écran à afficher n'a pas encore été défini;
  if(!screen) {
    return;
  }

  if (!isDrawDone && !!session) {
    return <WaitingScreen session={session} />
  }

  if (isDrawDone && !session) {
    return <ClosedScreen />
  }

  // Une session a été créé par la validation de l'email.
  // On redirige l'utilisateur vers la page du formulaire.
  if (isWinner) {
    if (localStorage.getItem("end_form_submitted") === "yes" || screen?.name === "FormScreen") {
      return <FormScreen session={session}/>
    } else {
      return <CongratzScreen setScreen={setScreen} />
    }
  }

  return renderScreen(screen);

}

export default App;