import styled from "@emotion/styled";

export default function BlueBox({ children, addStyles }) {
  return (
    <Wrapper style={addStyles}>
      <div className={"inner-box"}>{children}</div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;

  position: relative;
  width: 100%;
  max-width: 360px;
  background-color: rgba(13, 18, 32, 0.75);
  color: white;
  font-family: Averta, serif;

  .inner-box {
    position: relative;
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-clip: padding-box;
    border-style: solid;
    border-width: 5px;
    border-image: var(--gradient) 1;
  }

  h2,
  p,
  .bb-message {
    ${
      "" /* background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    }
    text-transform: uppercase;
  }

  .bb-message {
    margin-top: 32px;
    margin-bottom: 40px;
    font-size: clamp(1.2rem, 7vw, 1.8rem);
    font-weight: 700;
  }

  .bb-body {
    font-weight: 700;
    font-size: 1.8rem;
  }

  .bb-title {
    font-weight: 700;
    font-size: 2.4rem;
  }

  .errors {
    font-size: 10px;
    text-align: start;
    color: #ec3b24;

    ul {
      margin-top: 0;
      padding-top: 0;
      padding-left: 12px;
    }
  }

  .link {
    font-size: 10px;
    color: white;
  }

  a {
    color: inherit;
  }
`;
